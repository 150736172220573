import React from "react";
import { useGlobalContext } from "./Context.js";

const Chat = () => {
	const { messages, currentConversation } = useGlobalContext();

	if (messages.length > 0) {
		return (
			<ul className="feed" id="feed">
				{currentConversation?.map((chatMessage, index) =>
					chatMessage.user !== "AI LABS" ? (
						<li key={index} className="user">
							<h6>{chatMessage.user}</h6>
							<p>{chatMessage.message + " ?"}</p>
						</li>
					) : (
						<li key={index} className="ai">
							<h6>{chatMessage.user}</h6>
							<p>{chatMessage.message}</p>
						</li>
					)
				)}
			</ul>
		);
	}
};

export default Chat;
