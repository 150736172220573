const reducer = (state, { type, payload }) => {
	if (type === "SET_USERNAME") {
		localStorage.setItem("username", payload);
		return {
			...state,
			username: payload,
		};
	} else if (type === "GET_QUESTION") {
		state.messages.push({
			user: state.username,
			title: state.currentTitle || state.question,
			message: state.question,
		});

		return {
			...state,
			isLoading: true,
			currentConversation: [
				...state.currentConversation,
				{
					user: state.username,
					title: state.currentTitle || state.question,
					message: state.question,
				},
			],
		};
	} else if (type === "GET_RESPONSE") {
		state.messages.push({
			user: "AI LABS",
			title: state.currentTitle,
			message: payload,
		});
		const new_array = state.messages.filter(
			(mes) => mes.title === state.currentTitle
		);

		return {
			...state,
			question: "",
			currentConversation: new_array,
			isLoading: false,
		};
	} else if (type === "RESET") {
		localStorage.clear();
		return {
			...state,
			messages: [],
			isLoading: false,
			question: "",
			currentTitle: "",
			arrayTitle: [],
			currentConversation: [],
		};
	} else if (type === "LOGOUT") {
		localStorage.removeItem("username");
		localStorage.removeItem("role");
		// setTimeout(() => {
		// 	window.location.replace("http://localhost:8000/login");
		// }, 500);
		return {
			...state,
			username: null,
		};
	} else if (type === "SET_QUESTION") {
		return {
			...state,
			question: payload,
		};
	} else if (type === "CREATE_NEW_CONVERSATION") {
		return {
			...state,
			currentConversation: [],
			currentTitle: "",
		};
	} else if (type === "SET_CURRENT_CHAT") {
		const new_array = state.messages.filter((mes) => mes.title === payload);
		state.currentConversation = new_array;
		return {
			...state,
			currentConversation: new_array,
		};
	}
	throw new Error(`errore ${type}`);
};

export default reducer;
